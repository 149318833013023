<template>
  <div class="about">
    <div class="computer">
      <!-- <section class="one">
      <div class="content">
        <div class="boxs">
          <el-carousel :height="bannerHeight" :interval="4000">
              <el-carousel-item v-for="(item, index) in aboutData.imgs" :key="index">
                <img style="width: 100%; height: 100%" class="cp" @click="Skip(item.href)" :src="imgTransition(item.urlName)" alt="">
              </el-carousel-item>
            </el-carousel>
        </div>
      </div>
    </section> -->
    <!-- 关于我们 -->
    <section class="two">
      <div class="two-title">
        <div class="dec">
          <img src="@/assets/images/dec_b_left.png" alt="">
          <span class="big_title">{{node.aboutTranslate}}</span>
          <img src="@/assets/images/dec_b_right.png" alt="">
        </div>
      </div>
      <div class="two-dec">
        <div id="two-dec-about" v-loading="htmlLoading" v-html="aboutData.aboutDetail"></div>
      </div>
    </section>
    <!-- 我们资质 -->
    <section class="four">
      <div class="four-title">
        <div class="dec">
          <img src="@/assets/images/dec_b_left.png" alt="">
          <span class="big_title">{{node.certificationTranslate}}</span>
          <img src="@/assets/images/dec_b_right.png" alt="">
        </div>
      </div>
      <div class="four-main">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in aboutData.qualification" :key="index">
              <img class="four-item-img" :src="imgTransition(item.urlName)" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 合作伙伴 -->
    <!-- <section class="five">
      <div class="five-title">
        <div class="dec">
          <img src="@/assets/images/dec_b_left.png" alt="">
          <span class="big_title">{{node.partnerTranslate}}</span>
          <img src="@/assets/images/dec_b_right.png" alt="">
        </div>
      </div>
      <div class="five-main">
        <img class="five-item" :src="imgTransition(item.urlName)" @click="Skip(item.href)" style="border: 1px solid #F3F3F3" :class="{'cp': item.href}" alt="" v-for="(item, index) of aboutData.partners" :key="index" />
      </div>
    </section> -->
    <!-- 联系我们 -->
    <!-- <div class="computer">
      <section class="six">
        <div class="six-title">
          <div class="dec">
            <img src="@/assets/images/dec_b_left.png" alt="">
            <span class="big_title">{{node.contactTranslate}}</span>
            <img src="@/assets/images/dec_b_right.png" alt="">
          </div>
        </div>
        <div class="six-main">
          <div class="six-address">
            <span class="six-message">{{node.leaveMsgTranslate}}</span>
            <el-form ref="ruleForm" :rules="rules" :model="formInline" class="demo-form-inline">
              <el-row :gutter="40">
                <el-col :span="8">
                  <el-form-item label="" prop="name">
                    <el-input v-model="formInline.name" :placeholder="node.yourNameTranslate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="" prop="phone">
                    <el-input v-model="formInline.phone" :placeholder="node.yourPhoneTranslate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="" prop="email">
                    <el-input v-model="formInline.email" :placeholder="node.yourEmailTip"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="" prop="desc">
                <el-input type="textarea" v-model="formInline.desc" :autosize="{ minRows: 9, maxRows: 20}" :placeholder="node.writeMsgTranslate"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="bt_r" type="primary" @click="onSubmit">{{node.submitTranslate}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </section>
    </div> -->
    <div class="phone">
      <section class="six">
        <div class="six-title">
          <div class="dec">
            <img src="@/assets/images/dec_b_left.png" alt="">
            <span>{{node.contactTranslate}}</span>
            <img src="@/assets/images/dec_b_right.png" alt="">
          </div>
        </div>
        <div class="six-main">
          <div class="six-address">
            <span class="six-message">{{node.leaveMsgTranslate}}</span>
            <el-form ref="ruleForm" :rules="rules" :model="formInline" class="demo-form-inline">
              <el-row :gutter="40">
                <el-col :span="24">
                  <el-form-item label="" prop="name">
                    <el-input v-model="formInline.name" :placeholder="node.yourNameTranslate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="" prop="phone">
                    <el-input v-model="formInline.phone" :placeholder="node.yourPhoneTranslate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="" prop="email">
                    <el-input v-model="formInline.email" :placeholder="node.yourEmailTip"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="" prop="desc">
                <el-input type="textarea" v-model="formInline.desc" :autosize="{ minRows: 5, maxRows: 10}" :placeholder="node.writeMsgTranslate"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="bt_r" type="primary" @click="onSubmit">{{node.submitTranslate}}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="six-compay">
            <span class="six-text">{{node.siteName}}</span>
          </div>
          <div class="six-info">
            <div class="six-item"><i class="phone"></i>{{node.phoneTranslate}}：{{node.phoneValue}}</div>
            <div class="six-item"><i class="email"></i>{{node.emailTranslate}}：{{node.emailValue}}</div>
            <div class="six-item"><i class="address"></i>{{node.addressTranslate}}：{{node.addressValue}}</div>
          </div>
        </div>
      </section>
    </div>
    </div>
    <div class="phone">
      <!-- <section class="one">
      <div class="content">
        <div class="boxs">
          <el-carousel :height="bannerHeight" :interval="4000">
              <el-carousel-item v-for="(item, index) in aboutData.imgs" :key="index">
                <img style="width: 100%; height: 100%" class="cp" @click="Skip(item.href)" :src="imgTransition(item.urlName)" alt="">
              </el-carousel-item>
            </el-carousel>
        </div>
      </div>
      </section> -->
      <!-- 关于我们 -->
      <section class="two">
        <div class="two-title">
          <div class="dec dflex_center">
            <img src="@/assets/images/dec_b_left.png" alt="">
            <span>{{node.aboutTranslate}}</span>
            <img src="@/assets/images/dec_b_right.png" alt="">
          </div>
        </div>
        <div class="two-dec">
          <div v-html="aboutData.aboutDetail" id="two-dec-about"></div>
        </div>
      </section>
      <!-- 我们资质 -->
      <section class="four">
      <div class="four-title">
        <div class="dec dflex_center">
          <img src="@/assets/images/dec_b_left.png" alt="">
          <span>{{node.certificationTranslate}}</span>
          <img src="@/assets/images/dec_b_right.png" alt="">
        </div>
      </div>
      <div class="four-main" style="background: #fff">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in aboutData.qualification" :key="index">
              <img class="four-item-img" :src="imgTransition(item.urlName)" alt="">
            </div>
          </div>
        </div>
      </div>
      </section>
    </div>
    <phone-menu></phone-menu>
  </div>
</template>

<script>
import phoneMenu from '../components/phoneMenu.vue'
import 'swiper/dist/idangerous.swiper.css'
import Swiper from 'swiper/dist/idangerous.swiper.js'
import { Message } from 'element-ui';
import { siteabout, siteleavemsg } from '@/api/index'
export default {
  name: 'About',
  data() {
    const validatePass = (rule, valueData, callback) => {
      let value = valueData ? valueData.trim() : '';
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (value === '' || value === null || value === undefined) {
        callback(new Error(this.node.yourEmailTip))
      } else if (reg.test(value)) {
        callback()
      } else {
        callback(new Error(this.node.yourEmailTranslate));
      }
    };
    return {
      bannerHeight: '550px',
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'change' }
        ],
        email: [
          { validator: validatePass, trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入留言信息', trigger: 'change' }
        ],
      },
      formInline: {},
      aboutData: {
        imgs: [], // 横幅
        aboutDetail: '',  // 公司简介
        qualification: [], // 资质
        partners: [] // 合作伙伴
      },
      ifPhone: false,
      htmlLoading: false
    }
  },
  components: {
    phoneMenu
  },
  computed: {
    node(){
      return this.$store.state.node
    },
    liftAboutNum(){
      return this.$store.state.liftAboutNum
    },
  },
  mounted() {
    this.siteabout()
    this.getData()
    let result = window.matchMedia('(max-width: 1300px)');
    if (result.matches) {
    } else {
      // this.bannerHeight = document.getElementsByClassName('one')[0].offsetHeight + 'px'
      this.bannerHeight = '600px'
    }
    let maxResult = window.matchMedia('(max-width: 980px)');
    if (maxResult.matches) {
        // this.ifPhone = true;
        // this.bannerHeight = '200px'
    }
  },
  watch: {
    liftAboutNum(newVal, oldVal){
      if(newVal || newVal == 0) {
        this.getImgIntoView(newVal, true)
      }
    }
  },
  methods: {
    getData() {
      this.rules.name[0].message = this.node.yourNameTranslate
      this.rules.phone[0].message = this.node.yourPhoneTranslate
      this.rules.desc[0].message = this.node.writeMsgTranslate
      this.$forceUpdate()
    },
    // 横幅跳转
    Skip(url) {
      if(url) {
        window.open(url)
      }
    },
    // 提交留言
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = {
            consultant: this.formInline.name,
            phone: this.formInline.phone,
            email: this.formInline.email,
            msg: this.formInline.desc,
            siteCode: this.$store.state.siteCode
          }
          siteleavemsg(data).then((res) => {
            if(res && res.code == 0) {
              this.formInline = {}
              this.$refs['ruleForm'].resetFields();
              Message.success({ message: '提交成功！' })
            }
          }).catch(err => {
            console.log('err', err);
          })
        } else {
          console.log('error submit!!');
          Message.error({ message: '请输入联系信息及留意信息' })
          return false;
        }
      });
    },
    getImgIntoView(num = 0, type = false) {
      if(type) {
        let dom = document.getElementById('two-dec-about')
        let imgList = dom.getElementsByTagName('img')
        imgList[num].scrollIntoView(false)
      } else {
        let _this = this
        let dom = document.getElementById('two-dec-about')
        let imgList = dom.getElementsByTagName('img')
        if(_this.liftAboutNum != 1) {
          for (let index = 0; index < imgList.length; index++) {
            const img = imgList[index];
            if(index == _this.liftAboutNum) {
              img.onload = function() {
                imgList[index].scrollIntoView(false)
                _this.htmlLoading = false
              }
              break
            }
          }
        } else {
          _this.htmlLoading = false
        }
      }
    },
    // 获取关于我们
    siteabout() {
      let siteCode = this.$store.state.siteCode
      this.htmlLoading = true
      siteabout(siteCode).then((res) => {
        if (res && res.code == 0) {
          this.aboutData = {
            imgs: JSON.parse(res.data.imgs),
            aboutDetail: res.data.aboutDetail,
            qualification: JSON.parse(res.data.qualification),
            partners: JSON.parse(res.data.partners)
          }
          this.$nextTick(() => {
            //页面渲染完成再加载Swiper轮播图
            this.getImgIntoView()
            new Swiper('.swiper-container',{
              mode : 'horizontal',
              slidesPerView: 3,
              spaceBetween: 30,
              centeredSlides: true,
              loop: true,
              autoplay: 3000,
              autoplayDisableOnInteraction: false
            })
          })
        }
      }).catch(err => {
        console.log('err', err);
        this.htmlLoading = false
      })
    },
    // 图片路径转换
    imgTransition(str) {
        return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
    },
  }
}
</script>

<style lang="scss">
  .dflex_center {
    display: flex;
    align-items: center;
    span {
      font-size: 2vh !important;
      height: auto !important;
    }
  }
::v-deep .el-carousel__indicators--horizontal {
  bottom: 40px;
}
.about {
  .one {
    height: 580px;
    .one-main {
      padding: 337px 0 0 258px;
      box-sizing: border-box;
      .one-en {
        font-size: 40px;
        font-weight: 300;
        color: #F9F9F9;
      }
      .one-zh {
        font-size: 40px;
        color: #FFFFFF;
      }
      .one-dec {
        margin-top: 22px;
        width: 786px;
        font-size: 22px;
        color: #FFFFFF;
        line-height: 36px;
      }
      .one-btn {
        margin-top: 32px;
        width: 128px;
        height: 48px;
        background: #5C6AA1;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 300;
        color: #FFFFFF;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
      }
    }
  }
  .two {
    width: 100%;
    min-height: 500px;
    background-color: #F7F9FF;
    background-size: cover;
    padding-bottom: 20px;
    .two-title {
      padding-top: 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .two-img {
        width: 744px;
        height: 104px;
      }
    }
    .two-dec {
      width: 1440px;
      overflow: hidden;
      margin: 0 auto;
      margin-top: 36px;
      font-size: 18px;
      font-weight: 300;
      color: #333333;
      line-height: 1.5;
      // padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      .two-dec-about {
        width: 100% !important;
        img {
          width: 10% !important;
        }
      }
    }
  }
  .four {
    height: 500px;
    // background: #FFF;
    background-color: #F7F9FF;
    .four-title {
      padding-top: 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .four-img {
        width: 610px;
        height: 104px;
      }
    }
    .four-main {
      // width: 1360px;
      height: 519px !important;
      overflow: hidden;
      margin: 45px 240px 0;
      box-sizing: border-box;
      background-color: #fff;
      padding-left: 65px;
      .swiper-container {
        width: 100%;
        height: 100%;
        margin-top: 50px;
      }
      .swiper-slide {
        width: 352px !important;
        height: 519px !important;
        margin-right: 116px;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -ms-transition: 300ms;
        -o-transition: 300ms;
        transition: 300ms;
      }
      .four-item-img {
        width: 352px;
        height: auto;
      }
      .swiper-slide-visible {
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
      }
      .swiper-slide-active {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  .five {
    min-height: 500px;
    background: #F7F9FF;
    .five-title {
      padding-top: 45px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .five-img {
        width: 693px;
        height: 103px;
      }
    }
    .five-main {
      margin-top: 40px;
      padding: 0 240px;
      box-sizing: border-box;
      .five-item {
        width: 268px;
        height: 110px;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
  .six {
    height: 520px;
    background: #FFF;
    overflow: hidden;
    .six-title {
      padding-top: 40px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .six-img {
        width: 697px;
        height: 104px;
      }
    }
    .six-main {
      margin: 0px 240px 0 240px;
      background: #fff;
      .six-address {
        width: 100%;
        height: 357px;
        padding: 30px 30px 50px;
        .six-message {
          width: 100%;
          height: 40px;
          font-size: 24px;
          margin-bottom: 20px;
          display: inline-block;
        }
        /deep/ .el-input__inner {
          border-radius: 10px;
        }
        /deep/ .el-textarea__inner {
          border-radius: 10px;
        }
      }
      .six-compay {
        width: 100%;
        height: 108px;
        line-height: 108px;
        font-size: 30px;
        color: #151515;
        padding: 0 30px;
        box-sizing: border-box;
        overflow: hidden;
        .six-text {
          width: 100%;
          height: 100%;
          display: inline-block;
          border-bottom: 1px solid #E6E6E6;
        }
      }
      .six-info {
        height: 180px;
        padding: 40px 30px 50px;
        box-sizing: border-box;
        font-size: 22px;
        font-weight: 300;
        color: #4A4A4A;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .six-item {
          width: 40%;
          display: flex;
          align-items: center;
          font-size: 24px;
          &:nth-child(1),  &:nth-child(2){
            margin-bottom: 30px;
          }
          &:nth-child(1) {
            width: 60%;
          }
          &:nth-child(3) {
            width: 100%;
          }
        }
        .address {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_address.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
        }
        .phone {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_phone.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
        }
        .email {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_email.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
          position: relative;
          top: 3px;
        }
        .net {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_url.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
          position: relative;
          top: 4px;
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .five-item {
    width: 16.69vw !important;
    height: 10vw !important;
    &:nth-child(5n) {
      margin-right: 20px !important;
    }
  }
  .six {
    height: 600px !important;
  }
  #two-dec-about {
    img {
      width: 75% !important;
    }
  }
}
@media screen and (max-width: 1250px) {
  .five-item {
    width: 16.69vw !important;
    height: 10vw !important;
    &:nth-child(5n) {
      margin-right: 20px !important;
    }
  }
  .six {
    height: 590px !important;
  }
  #two-dec-about {
    img {
      width: 75% !important;
    }
  }
}
@media screen and (max-width: 1300px) {
  .five-item {
    width: 16.69vw !important;
    height: 10vw !important;
    &:nth-child(5n) {
      margin-right: 20px !important;
    }
  }
  .six {
    height: 560px !important;
  }
  #two-dec-about {
    img {
      width: 75% !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .five-item {
    width: 16.69vw !important;
    height: 10vw !important;
    &:nth-child(5n) {
      margin-right: 20px !important;
    }
  }
  .six {
    min-height: 580px !important;
  }
  #two-dec-about {
    img {
      width: 75% !important;
    }
  }
}
@media screen and (max-width: 980px) {
  .dec {
    height: 6vw;
    text-align: center;
    span {
      height: 100%;
      display: inline-block;
      padding: 0 2vw;
      font-size: 5vw;
      font-weight: 500;
      color: #151515;
      line-height: 1.5;
    }
    & img{
      width: 15vw;
      height: 3vw;
    }
  }
  .about {
    .two-dec {
      width: 90vw !important;
    }
    .four {
      height: 45vw !important;
      .four-main {
        height: 450px !important;
        margin-top: 0 !important;
      }
      .four-title {
        padding-top: 4vw !important;
      }
      .four-item-img {
        width: 25vw !important;
      }
    }
    .five {
      min-height: 50vw !important;
      .five-main {
        margin-top: 2.5vw !important;
        margin-left: 2vw;
      }
      .five-item {
        width: 23vw !important;
        height: 13vw !important;
        &:nth-child(5n) {
          margin-right: 20px !important;
        }
      }
    }
    .six {
      height: 128vw !important;
      font-size: 2.5vw;
      .six-address {
        height: 70vw !important;
        font-size: 6vw !important;
        ::v-deep .el-form-item {
          margin-bottom: 4.2vw;
          font-size: 2.5vw !important;
        }
        ::v-deep .el-input__inner {
          height: 5vw;
          font-size: 2.5vw !important;
        }
        ::v-deep .el-textarea__inner {
          font-size: 2.5vw;
        }
        .six-message {
          font-size: 2.5vw !important;
        }
      }
      .six-main {
        margin: 4.27vw 5.5vw 0 5.5vw !important;
      }
      .six-compay {
        .six-text {
          font-size: 2.5vw;
        }
      }
      .six-info {
        height: 28vw !important;
        margin-top: 4vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .six-item {
          width: 100% !important;
          .address {
            width: 4vw !important;
            height: 4vw !important;
            margin-right: 2vw !important;
          }
          .phone {
            width: 4vw !important;
            height: 4vw !important;
            margin-right: 2vw !important;
          }
          .email {
            width: 4vw !important;
            height: 4vw !important;
            margin-right: 2vw !important;
          }
        }
      }
    }
  }
  .el-input__inner {
    height: 4vh !important;
  }
}
</style>