<template>
    <!-- 合作伙伴 -->
    <div>
        <!-- <div class="business"> -->
            <div class="computer">
                <div class="box">
                    <div class="join">
                        <div class="join_item">
                            <div class="join_img"><img class="join_img1" src="@/assets/images/cooperate.png" alt=""></div>
                            <div class="join_content">
                                <p class="join_title">{{getLanguageText(this.languageType, '诚邀全球优质物流企业')}}</p>
                                <p class="join_value">{{node.phoneTranslate}}: {{getLanguageText(this.languageType, '合作电话值')}}</p>
                                <p class="join_value">{{node.emailTranslate}}: {{getLanguageText(this.languageType, '合作邮箱值')}}</p>
                            </div>
                        </div>
                        <div class="join_item">
                            <div class="join_img"><img class="join_img2" src="@/assets/images/hire.png" alt=""></div>
                            <div class="join_content">
                                <p class="join_title">{{getLanguageText(this.languageType, '劳务派遣人才合作')}}</p>
                                <p class="join_value">{{node.phoneTranslate}}: {{getLanguageText(this.languageType, '招聘电话值')}}</p>
                                <p class="join_value">{{node.emailTranslate}}: {{getLanguageText(this.languageType, '招聘邮箱值')}}</p>
                            </div>
                        </div>
                        <!-- <div class="tojoin">{{languageType == 1 ? '我要加盟' : 'Partner with Us'}}</div>
                        <div class="invite">{{languageType == 1 ? '诚邀全球优质物流企业' : 'Inviting Global High Quality Logistics Enterprises'}}</div>
                        <div class="contact">
                            <span class="contact_item contact_item_left"></span>
                            <span class="contact_text">{{node.phoneTranslate}}: 0757-81768696<span class="seizeSeat"></span>{{node.emailTranslate}}: cs_cn@pj-logistics.com</span>
                            <span class="contact_item contact_item_right"></span>
                        </div> -->
                    </div>
                    <section class="five">
                        <div class="five-title">
                            <div class="dec">
                                <img src="@/assets/images/dec_b_left.png" alt="">
                                <span class="big_title">{{node.partnerTranslate}}</span>
                                <img src="@/assets/images/dec_b_right.png" alt="">
                            </div>
                        </div>
                        <div class="five-main">
                            <img class="five-item" :src="imgTransition(item.urlName)" @click="Skip(item.href)" style="border: 1px solid #F3F3F3" :class="{'cp': item.href}" alt="" v-for="(item, index) of aboutData.partners" :key="index" />
                        </div>
                    </section>
                </div>
            </div>
        <!-- </div> -->
        <div class="phone">
            <div class="box">
                <div class="phone_join">
                    <div class="join_item mb2vh">
                        <div class="join_img"><img class="join_img1" src="@/assets/images/cooperate.png" alt=""></div>
                        <div class="join_content">
                            <p class="join_title">{{getLanguageText(this.languageType, '诚邀全球优质物流企业')}}</p>
                            <p class="join_value">{{node.phoneTranslate}}: {{getLanguageText(this.languageType, '合作电话值')}}</p>
                            <p class="join_value">{{node.emailTranslate}}: {{getLanguageText(this.languageType, '合作邮箱值')}}</p>
                        </div>
                    </div>
                    <div class="join_item">
                        <div class="join_img"><img class="join_img2" src="@/assets/images/hire.png" alt=""></div>
                        <div class="join_content">
                            <p class="join_title">{{getLanguageText(this.languageType, '劳务派遣人才合作')}}</p>
                            <p class="join_value">{{node.phoneTranslate}}: {{getLanguageText(this.languageType, '招聘电话值')}}</p>
                            <p class="join_value">{{node.emailTranslate}}: {{getLanguageText(this.languageType, '招聘邮箱值')}}</p>
                        </div>
                    </div>
                </div>
                <section class="phone_five">
                    <div class="five-title">
                        <div class="dec dflex_center">
                            <img src="@/assets/images/dec_b_left.png" alt="">
                            <span>{{node.partnerTranslate}}</span>
                            <img src="@/assets/images/dec_b_right.png" alt="">
                        </div>
                    </div>
                    <div class="five-main">
                        <img class="five-item" :src="imgTransition(item.urlName)" @click="Skip(item.href)" style="border: 1px solid #F3F3F3" :class="{'cp': item.href}" alt="" v-for="(item, index) of aboutData.partners" :key="index" />
                    </div>
                </section>
            </div>
            <phone-menu></phone-menu>
        </div>
    </div>
</template>

<script>
import phoneMenu from '../components/phoneMenu.vue'
import { siteabout } from '@/api/index'
export default {
    data() {
        return {
            aboutData: {
                imgs: [], // 横幅
                aboutDetail: '',  // 公司简介
                qualification: [], // 资质
                partners: [] // 合作伙伴
            },
        }
    },
    computed: {
        node(){
            return this.$store.state.node
        },
        languageType(){
            return this.$store.state.languageType
        },
    },
    components: {
        phoneMenu
    },
    mounted() {
        this.siteabout()
    },
    methods: {
        // 获取关于我们
        siteabout() {
            let siteCode = this.$store.state.siteCode
            siteabout(siteCode).then((res) => {
                if (res && res.code == 0) {
                    this.aboutData = {
                        imgs: JSON.parse(res.data.imgs),
                        aboutDetail: res.data.aboutDetail,
                        qualification: JSON.parse(res.data.qualification),
                        partners: JSON.parse(res.data.partners)
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        },
        // 图片路径转换
        imgTransition(str) {
            return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
        },
        // 横幅跳转
        Skip(url) {
            if(url) {
                window.open(url)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .mb2vh {
        margin-bottom: 2vh;
    }
    .dflex_center {
        display: flex;
        align-items: center;
        span {
            font-size: 2vh !important;
            height: auto !important;
        }
        img {
            width: 15vw;
            height: 3vw;
        }
    }
    .phone {
        .phone_join {
            width: 100%;
            margin-bottom: 2vh;
            .join_item {
                height: 20vh;
                width: 100%;
                color: #fff;
                border-radius: 2.6vh;
                padding: 2vh;
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                border: 0.1vh solid #fff;
                background: linear-gradient(to bottom, #1c70dc40, #2b67f2de);
                .join_img {
                    width: 19vh;
                    .join_img1 {
                        width: 19vh;
                    }
                    .join_img2 {
                        width: 16vh;
                    }
                }
                .join_content {
                    height: 100%;
                    margin-left: 1vh;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    p {
                        // margin: 1vh 0;
                    }
                    .join_title {
                        font-size: 2vh;
                        font-weight: 600;
                    }
                    .join_value {
                        font-size: 1.7vh;
                        font-weight: 600;
                    }
                }
            }
            .tojoin {
                height: 5vh;
                font-size: 2.5vh;
                line-height: 5vh;
                width: 30vh;
                font-weight: 500;
                border-radius: 0.5vh;
                text-align: center;
                border: 0.1vh solid #fff;
                background: linear-gradient(to bottom, #fd6202, #dc391c);
                box-shadow: -0.1vh 0 red, 0 0.1vh red, 0.1vh 0 red, 0 -0.1vh red;
            }
            .invite {
                font-size: 4vh;
                margin: 2vh 0;
            }
            .contact {
                height: 6vh;
                border: 0 solid #4d4d4d;
                font-size: 2.2vh;
                display: flex;
                align-items: center;
                color: #fff;
                .contact_text {
                    height: 6vh;
                    line-height: 6vh;
                    background-color: #4d4d4d;
                }
                .contact_item {
                    width: 6vh;
                    height: 6vh;
                    display: inline-block;
                    background-color: #4d4d4d;
                }
                .contact_item_left {
                    margin-right: -0.1vh;
                    clip-path: polygon(3vh 0, calc(100% - 0px) 0, 100% 3vh, 100% calc(100% - 0px), calc(100% - 3vh) 100%, 3vh 100%, 0 calc(100% - 3vh), 0 3vh);
                }
                .contact_item_right {
                    margin-left: -1px;
                    clip-path: polygon(3vh 0, calc(100% - 3vh) 0, 100% 3vh, 100% calc(100% - 3vh), calc(100% - 3vh) 100%, 3vh 100%, 0 calc(100% - 0px), 0 0px)
                }
                .seizeSeat {
                    display: inline-block;
                    width: 1.5vh;
                }
            }
        }
        .phone_five {
            padding: 1vh 0;
            background: #F7F9FF;
            .five-title {
                padding-top: 1vh;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
            }
            .five-main {
                margin-top: 4vh;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .five-item {
                    width: 15vh;
                    height: 8vh;
                    margin-bottom: 2vh;
                    margin-left: 1vh;
                    margin-right: 1vh;
                }
            }
        }
    }
.five {
    min-height: 500px;
    background: #F7F9FF;
    .five-title {
      padding-top: 45px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .five-img {
        width: 693px;
        height: 103px;
      }
    }
    .five-main {
      margin-top: 40px;
    //   padding: 0 240px;
      box-sizing: border-box;
      .five-item {
        width: 268px;
        height: 110px;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
}
.join {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0;
    .join_item {
        height: 300px;
        width: 46%;
        color: #fff;
        border: 1px solid red;
        border-radius: 26px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #fff;
        background: linear-gradient(to bottom, #1c70dc40, #2b67f2de);
        // linear-gradient(to bottom, #2b67f2de, #dc391c00)
        // box-shadow: -1px 0 red, 0 1px red, 1px 0 red, 0 -1px red;
        // box-shadow: -1px 0 #94abea, 0 1px #94abea, 1px 0 #94abea, 0 -1px #94abea;
        .join_img {
            width: 200px;
            .join_img1 {
                width: 200px;
            }
            .join_img2 {
                width: 160px;
            }
        }
        .join_content {
            margin-left: 20px;
            p {
                margin: 40px 0;
            }
            .join_title {
                font-size: 30px;
                font-weight: 600;
            }
            .join_value {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .tojoin {
        height: 50px;
        font-size: 25px;
        line-height: 50px;
        width: 300px;
        font-weight: 500;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #fff;
        background: linear-gradient(to bottom, #fd6202, #dc391c);
        box-shadow: -1px 0 red, 0 1px red, 1px 0 red, 0 -1px red;
    }
    .invite {
        font-size: 40px;
        margin: 20px 0;
    }
    .contact {
        // width: 620px;
        height: 60px;
        border: 0px solid #4d4d4d;
        font-size: 22px;
        display: flex;
        align-items: center;
        color: #fff;
        .contact_text {
            height: 60px;
            // width: 500px;
            line-height: 60px;
            background-color: #4d4d4d;
        }
        .contact_item {
            width: 60px;
            height: 60px;
            display: inline-block;
            background-color: #4d4d4d;
        }
        .contact_item_left {
            margin-right: -1px;
            clip-path: polygon(30px 0, calc(100% - 0px) 0, 100% 30px, 100% calc(100% - 0px), calc(100% - 30px) 100%, 30px 100%, 0 calc(100% - 30px), 0 30px);
        }
        .contact_item_right {
            margin-left: -1px;
            clip-path: polygon(30px 0, calc(100% - 30px) 0, 100% 30px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 30px 100%, 0 calc(100% - 0px), 0 0px)
        }
        .seizeSeat {
            display: inline-block;
            width: 15px;
        }
    }
}
</style>